import getConfig from "next/config";
import { getWindowEnv } from "./windowEnv";
const DEFAULT_PREFIX = "NEXT_PUBLIC";

const config = getConfig();
const prc = config?.publicRuntimeConfig || {};

function isBrowser() {
  //@ts-ignore
  return Boolean(typeof window !== "undefined");
}

function getFiltered() {
  return Object.keys(process.env)
    .filter(key => new RegExp(`^${DEFAULT_PREFIX}_`, "i").test(key))
    .reduce((env, key) => {
      //@ts-ignore
      env[key] = process.env[key];
      return env;
    }, {});
}

export default function env(key = "") {
  const windowenv = getWindowEnv();
  const keys = prc;
  const safeKey = `${DEFAULT_PREFIX}_${key}`;
  //@ts-ignore
  if (isBrowser() && windowenv) {
    //@ts-ignore
    return windowenv[safeKey] || "";
  } else {
    if (process.env && process.env[key]) {
      return process.env[key];
    } else if (keys && keys[key]) {
      return keys[key];
    } else {
      return key.length ? process.env[safeKey] : getFiltered();
    }
  }
}
